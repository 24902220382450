(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
var validate = require('./lib/validation');

/* CONTACT FORM SUBMISION */
module.exports = function() {
  var contactForm  = document.querySelector('#contact_form');
  var formName     = document.querySelector("#form_name");
  var formEmail    = document.querySelector("#form_email");
  var formMessage  = document.querySelector("#form_message");
  var formResponse = document.querySelector("#form_response");
  var honeypot     = document.querySelector("#form_url");
  var defaultEmail = "fourknivesdeli@gmail.com";

  // clear error stated on text inputs when typing
  Array.prototype.forEach.call(contactForm.querySelectorAll('fieldset'), function(el) {
    if ( el.querySelector('input') ) {
      el.querySelector('input').addEventListener('keypress', function(e) {
        el.classList.remove('invalid');
      });
    }
  });

  // on form reset
  contactForm.addEventListener('reset', function(e) {
    formName.parentElement.classList.remove('invalid');
    formEmail.parentElement.classList.remove('invalid');
		formMessage.parentElement.classList.remove('invalid');
    formResponse.innerHTML = '';
  });

  // on form submit
  contactForm.addEventListener('submit', function(e) {
    // stop submission
    e.preventDefault();

    // validate name
    var isValidName = validate.name(formName.value);

    // validate email
    var isValidEmail = validate.email(formEmail.value);

    if ( honeypot.value == '' ) {
      if ( isValidName && isValidEmail) {
        // remove invalid class names
        formName.parentElement.classList.remove('invalid');
        formEmail.parentElement.classList.remove('invalid');

        // get form values
        var formData = {
          "defaultEmail" : defaultEmail,
          "form_name"    : formName.value,
          "form_email"   : formEmail.value,
          "form_message" : formMessage.value
        };

        // submit form
        submitForm(formData,'email');

        // clear form
        contactForm.reset();

        // display success message
        formResponse.innerHTML = '<span>Your submission has been sent!</span>';

      } else { // display invalid fileds
        if( !isValidName && !isValidEmail ) { 
          formName.parentElement.classList.add('invalid');
          formEmail.parentElement.classList.add('invalid');
          formResponse.innerHTML = '<span>You must provide a name and valid email.</span>';
        } else if( !isValidName ) { 
          formName.parentElement.classList.add('invalid');
          formResponse.innerHTML = '<span>Please enter your name.</span>';
        } else if( !isValidEmail ) { 
          formEmail.parentElement.classList.add('invalid');
          formResponse.innerHTML = '<span>A valid email is required.</span>';
        }
      }
    } else {
      formResponse.innerHTML = '<span>You failed the human test. If you are not a robot, please refresh and try again.</span>';
    }
  });

  function submitForm(data,file) {
    // create XMLHttpRequest Object
    var xhrContent = new XMLHttpRequest();

    // callback
    xhrContent.onreadystatechange = function() {
      if (xhrContent.readyState === 4 && xhrContent.status === 200) {
        console.log(xhrContent.responseText);
      }
    };

    // open AJAX request
    xhrContent.open('POST', file, true);

    // set header
    xhrContent.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    var dataString = JSON.stringify(data).slice(1,-1).replace(/"/g,'').replace(/:/g,'=').replace(/,/g,'&');
    xhrContent.send(dataString);
  }
}
},{"./lib/validation":6}],2:[function(require,module,exports){
var allEvents = require('./lib/events').allEvents;

function EventTracking(){};

// TRACK CLICK EVENTS
EventTracking.prototype.clicked = function(category,message,trigger,type){
  var _this = this;
  $(trigger).on('click', function(e) {

    if( type == 'dynamic') {
      if( message == '' ){
        var displayText = ($(this).text().trim());
      }
      else if( message == 'href' ) {
        var displayText = $(this).attr('href');
      }
      else if( message == 'link text' ) {
        var displayText = $(this).text();
      }
      else {
        var displayText = ($(this).find($(message)).text()).trim();
      }
      if(typeof(ga) == 'function'){
        ga('send', {
          hitType: 'event',
          eventCategory: category,
          eventAction: 'click',
          eventLabel: displayText
        });
      }
    }
    else {
      if(typeof(ga) == 'function'){
        ga('send', {
          hitType: 'event',
          eventCategory: category,
          eventAction: 'click',
          eventLabel: message
        });
      }
    }
  });
};

function initEventTracking(){
  var trackedEvents = [];
  for(i=0; i<allEvents.length; i++) {
    var eventCategory = allEvents[i].category;
    var eventMessage  = allEvents[i].message;
    var eventTrigger  = allEvents[i].trigger;
    var eventType     = allEvents[i].type;
    trackedEvents[i]  = new EventTracking();

    trackedEvents[i].clicked(eventCategory,eventMessage,eventTrigger,eventType);
  }
}

exports.EventTracking     = EventTracking;
exports.initEventTracking = initEventTracking;
},{"./lib/events":4}],3:[function(require,module,exports){
module.exports = function(){
  
  var debug = false;

  $('[data-bg-src]').each(function(){
    var $thisImage = $(this);
    var imgSrc = $thisImage.attr('data-bg-src');
    $thisImage.css('background-image', 'url('+imgSrc+')');
    /*DEBUG*/ if(debug){console.log('loaded bg image: '+imgSrc);}
  });

  $('img[data-image-src]').each(function(){
    var $thisImage = $(this);
    var imgSrc = $thisImage.attr('data-image-src');
    var downloadingImage = new Image();
    downloadingImage.onload = function(){
      $thisImage.addClass('image-loaded');
      $thisImage.attr('src', imgSrc);
      /*DEBUG*/ if(debug){console.log('loaded image: '+imgSrc);}
    };
    downloadingImage.src = imgSrc;
  });
}
},{}],4:[function(require,module,exports){
module.exports = {
  allEvents: [
    {
      category : 'Link',
      message  : 'href',
      trigger  : 'a:link',
      type     : 'dynamic'
    }
  ]
}
},{}],5:[function(require,module,exports){
module.exports = [
  {
    "featureType": "administrative",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels",
    "stylers": [
      {
        "color": "#fe0000"
      },
      {
        "lightness": "40"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "elementType": "labels",
    "stylers": [
      {
        "color": "#fe0000"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "lightness": "85"
      },
      {
        "saturation": "-100"
      },
      {
        "hue": "#ff0000"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ff0000"
      },
      {
        "saturation": "-100"
      },
      {
        "lightness": "96"
      },
      {
        "visibility": "simplified"
      },
      {
        "gamma": "1.00"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "saturation": "-100"
      },
      {
        "lightness": "80"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "color": "#dddddd"
      },
      {
        "lightness": "60"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "color": "#dddddd"
      },
      {
        "lightness": "50"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "color": "#eeeeee"
      },
      {
        "lightness": "40"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ba5858"
      },
      {
        "saturation": "-100"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ba5858"
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.icon",
    "stylers": [
      {
        "hue": "#ff0036"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "color": "#dddddd"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#00a1ff"
      },
      {
        "lightness": "95"
      },
      {
        "saturation": "0"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#000000"
      },
      {
        "lightness": "70"
      }
    ]
  }
];
},{}],6:[function(require,module,exports){
/* FORM VALIDATION */
module.exports = {
  name: function(name) {
    if ( name.length > 0 ) {
      return true;
    } else {
      return false;
    }
  },
  email: function(email) {
    var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( email.length > 0 && filter.test(email)) {
      return true;
    }
    else {
      return false;
    }
  }
}
},{}],7:[function(require,module,exports){
var mapStyles = require('./lib/map-styles');

module.exports = function() {
  var center = {lat: 40.852192, lng: -74.442041};
  var mapOptions =  {
    zoom                    : 15,
    center                  : center,
    scrollwheel             : false,
    draggable               : true,
    mapTypeControl          : false,
    streetViewControl       : false,
    scaleControl            : false,
    zoomControl             : true,
    panControl              : true,
    gestureHandling         : "cooperative",
    mapTypeId               : 'Styled'
  };

  var map = new google.maps.Map(document.getElementById('map'), mapOptions);

  map.mapTypes.set('Styled', new google.maps.StyledMapType(mapStyles, { name: 'Custom' }));

  var contentString = 'Four Knives Deli & Catering';

  var marker = new google.maps.Marker({
    position: center,
    map: map,
    title: 'Four Knives Deli & Catering'
  });

  marker.addListener('click', function() {
    map.setCenter(marker.getPosition());
  });

  google.maps.event.addDomListener(window, "resize", function() {
    map.setCenter(center);
  });
}

},{"./lib/map-styles":5}],8:[function(require,module,exports){
var validate = require('./lib/validation');

/* CONTACT FORM SUBMISION */
module.exports = function() {
  var signupForm      = document.querySelector('#signupForm');
  var signupEmail     = document.querySelector("#mce-EMAIL");
  var signupResponse  = document.querySelector("#signup-response");
  var errorMsg        = "Please enter a valid email.";
  var successMsg      = "Thank you! You must confirm the subscription in your inbox."
  var mailchimpUrl    = "//fourknivesdeli.us13.list-manage.com/subscribe/post-json?u=ba7fac688f003c74046468c45&id=5b7ecd864b";

  // remove invalid class on form when user begins typing
  Array.prototype.forEach.call(signupForm.querySelectorAll('input[type="text"]'), function (el,i) {
    el.addEventListener('keypress', function(e) {
      signupForm.classList.remove('invalid');
    });
  });

  // on reset clear response message
  signupForm.addEventListener('reset', function (e) {
    signupResponse.innerHTML = '';
    signupEmail.classList.remove('typing');
  },false);

  // add/remove typing class when changes input value
  signupEmail.addEventListener('input', function (e) {
    if (signupEmail.value.length > 0) {
      signupEmail.classList.add('typing');
    } else {
      signupEmail.classList.remove('typing');
    }
  }, false);
  

  // on submit
  signupForm.addEventListener('submit', function (e) {
    // stop submission
    e.preventDefault();
    if ( validate.email(signupEmail.value) ) {
      // remove invalid class names
      signupForm.classList.remove('invalid');
      signupResponse.innerHTML = '<span>Subscribing...</span>';
      var formData = $('#signupForm').serialize();

      // submit to mailchimp
      $.ajax({
        type: "GET",
        url: mailchimpUrl,
        data: formData,
        cache: false,
        dataType: "jsonp",
        jsonp: "c", // trigger MailChimp to return a JSONP response
        contentType: "application/json; charset=utf-8",
        error: function (error) {},
        success: function (data) {
          if (data.result != "success") { // email rejected
              signupForm.classList.add('invalid');

              //log error message
              console.log(data.msg);

              if (data.msg && data.msg.indexOf("already subscribed") >= 0) {
                signupResponse.innerHTML = '<span>You are already subscribed.</span>';
              } else {
                signupResponse.innerHTML = '<span>'+errorMsg+'</span>';
              }
          } else { // email accepted                
              // clear form
              signupForm.reset();

              // display success message
              signupResponse.innerHTML = '<span>'+successMsg+'</span>';
          }
        }
      });
    } else {
      // display invalid email message
      signupForm.classList.add('invalid');
      signupResponse.innerHTML = '<span>'+errorMsg+'</span>';
    }
  },false);
}
},{"./lib/validation":6}],9:[function(require,module,exports){
module.exports = function() {
  var scrollSpeed = 600;
  $('a[href*="#"]:not([href="#"])').click(function() {
    console.log(this);
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      var scrollPos = target.offset().top - $('.header').outerHeight();
      if (target.length) {
        $('html, body').animate({
          scrollTop: scrollPos
        }, scrollSpeed);
        return false;
      }
    }
  });

  $('a[href="#"]').click(function(e){
    e.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, scrollSpeed);
  })
};
},{}],10:[function(require,module,exports){
module.exports = function(){
  windowHeight = $(window).outerHeight();
  return windowHeight;
}

},{}],11:[function(require,module,exports){
module.exports = function(){
  windowWidth = $(window).outerWidth();
  return windowWidth;
}

},{}],12:[function(require,module,exports){
var getWindowWidth    = require('./components/window-width');
var getWindowHeight   = require('./components/window-height');
var smoothScrolling   = require('./components/smooth-scrolling');
var loadImages        = require('./components/image-loader');
var initMap           = require('./components/map');
var newsletter        = require('./components/newsletter');
var contactForm       = require('./components/contact-form');
var initEventTracking = require('./components/event-tracking').initEventTracking;
var setHeight;
var setWidth;

//ONREADY SCRIPTS
$(document).ready(function(){
  document.body.classList.remove('loading');
  getWindowWidth();
  getWindowHeight();
  smoothScrolling();
  loadImages();
  initMap();
	newsletter();
	contactForm();
  setHeight = $(window).outerHeight();
  setWidth = $(window).outerWidth();


  var feed = new Instafeed({
    get: 'user',
    clientId: 'eed43b82802344c29dac45e3d329bbd3',
    userId: '3535615234',
    accessToken: '3535615234.eed43b8.421a989222184525a0f4a9296af0964e',
    template: '<div class="instafeed-post"><a href="{{link}}" target="_blank" class="instafeed-post-img"><style>a[href="{{link}}"]:after{background-image:url({{image}});}</style></a></div>',
    resolution: 'standard_resolution',
    limit: 5
  });
  feed.run();
})

//ONRESIZE SCRIPTS
var onResizeScripts = function() {
  getWindowWidth();
  getWindowHeight();
  if(windowWidth > setWidth || Math.abs(windowHeight - setHeight) > 60) {
    $('.hero').css('height',$(window).outerHeight());
    $('.bg-ctn').css('height',$(window).outerHeight());
    setHeight = $(window).outerHeight();
    setWidth = $(window).outerWidth();
  }
}

$(window).on('resize',function(){
  onResizeScripts()
});

//ONLOAD SCRIPTS
var onLoadScripts = function() {
  initEventTracking();
  $('.hero').css('height',$(window).outerHeight());
  $('.bg-ctn').css('height',$(window).outerHeight());
  if(menuMasonry){
    $('.menu-wrapper').masonry({
      itemSelector: '.menu-category',
      columnWidth: '.menu-category-sizer'
    });
  }
  if(cateringMasonry){
    $('.catering-wrapper').masonry({
      itemSelector: '.catering-category',
      columnWidth: '.catering-category-sizer'
    });
  }
}

$(window).on('load',function(){
  document.body.classList.add('loaded');
  enableScroll();
  onLoadScripts();
});

/* ------------------------------------------- */

$('.nav-trigger').click(function(){
  $('html').toggleClass('menu-open');
});
$('.nav-close').click(function(){
  $('html').removeClass('menu-open');
});
$('.nav-links').click(function(){
  $('html').removeClass('menu-open');
});
$('.site-name a').click(function(){
  $('html').removeClass('menu-open');
});

// TODO: update on resize
$(function() {
    $('html').addClass('scroll-init');
    var controller = new ScrollMagic.Controller();

    // NAV BG
    new ScrollMagic.Scene({
      offset: 100
    }).setClassToggle("header", "trigger-active").addTo(controller);

    // NAV BG
    new ScrollMagic.Scene({
      offset: 100
    }).setClassToggle(".scroll", "trigger-active").addTo(controller);

    // ABOUT TITLE
    new ScrollMagic.Scene({
      triggerElement: '.about',
      reverse: false
    }).setClassToggle(".about-title-text", "trigger-active").addTo(controller);

    if($(window).outerWidth() >= 768) {
      // BG POSITION
      new ScrollMagic.Scene({
        triggerElement: '#newsletter',
        triggerHook: "onEnter"
      }).on("progress", function (event) {
        if (event.progress) {
          $('.bg-ctn').css({
            'position': 'absolute',
            'top': $('#newsletter').offset().top - $(window).outerHeight(),
            'bottom': 'auto'
          });
        } else {
          $('.bg-ctn').css({
            'position': 'fixed',
            'top': 'auto',
            'bottom': '0'
          });
        }
      }).addTo(controller);
    }
});

},{"./components/contact-form":1,"./components/event-tracking":2,"./components/image-loader":3,"./components/map":7,"./components/newsletter":8,"./components/smooth-scrolling":9,"./components/window-height":10,"./components/window-width":11}]},{},[12]);
